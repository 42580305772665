import request from '@/request'

export const getAboutus = (data = {}) => {
    return request({
      url: '/admin/system/get_aboutus',
      method: 'get',
      params: data
    })
}
export const updateAboutus = (data = {}) => {
  return request({
    url: '/admin/system/update_aboutus',
    method: 'post',
    data
  })
}