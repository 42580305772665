<template>
    <div class="form">
        <a-spin :spinning="spinning">
            <div class="form_title">About us</div>
            <Editor v-if="status" @onContent="onContent" :content="formData.content" />
            <a-button type="primary" @click="updateAboutusHandler" class="primary btn" style="margin-top: 10px">Submit</a-button>
        </a-spin>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import { getAboutus, updateAboutus } from '@/apis/aboutus' 
import Editor from '@/components/editor/editor'
export default {
    name: 'Aboutus',
    components: {
        Editor
    },
    data(){
        return {
            formData: {
                content: ''
            },
            labelCol: { span: 6 },
            wrapperCol: { span: 12 },
            spinning: false,
            status: false
        }
    },
    methods: {
        onContent(html){
            this.formData.content = html
        },
        async getAboutusHandler(){
            this.spinning = true
            const res = await getAboutus()
            this.spinning = false
            if(res){
                this.formData.content = res.data.content
                this.status = true
            }
        },
        async updateAboutusHandler(){
            this.spinning = true
            const res = await  updateAboutus(this.formData)
            this.spinning = false
            if(res){
                message.success('success')
            }
        }
    },
    mounted(){
        this.getAboutusHandler()
    }
}
</script>
<style lang="stylus" scoped>
.form
    width 880px
</style>